import axios from '@/api/api-request'

const projectUrl = '/webOnlineConsultation'

// 在线咨询管理-增加操作
export const add = (data) => {
  return axios.request({
    url: projectUrl + '/add.do',
    method: 'post',
    data: data
  })
}

// 在线咨询管理-查询操作
export const getInfo = (data) => {
  return axios.request({
    url: projectUrl + '/getInfo.do',
    method: 'post',
    params: data
  })
}
