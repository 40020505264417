<template>
  <div class="kuang_ping_nwes service-project">
    <div class="px1200 nimen_news">
      <div class="shouye_biapt clas_news_bt">
        <div class="mingz_news"><span><my-link href="/home-layout">首页</my-link> > <my-link href="/serviceProject">招商加盟</my-link></span></div>
      </div>
      <div class="chanp_biapt">
        <h2>招商加盟</h2>
      </div>
      <div class="news_list_ziye" v-if="firstReservice">
        <ul>
          <li >
            <img :src="httpPath + firstReservice.imgUrl" alt="">
            <div class="text">
              <h2 v-for="item in firstReservice.list" :key="item.id" :title="item.title">{{ item.title }}</h2>
            </div>
          </li>
        </ul>
      </div>
      <div class="news_list_ziye fill-img" v-if="secondReservice">
        <ul>
          <li >
            <img :src="httpPath + secondReservice.imgUrl" alt="">
          </li>
        </ul>
      </div>
      <div class="news_list_ziye fill-img" v-if="thirdReservice">
        <ul>
          <li >
            <img :src="httpPath + thirdReservice.imgUrl" alt="">
          </li>
        </ul>
      </div>
      <div class="news_list_ziye fill-img" v-if="fourthReservice">
        <ul>
          <li >
            <img :src="httpPath + fourthReservice.imgUrl" alt="">
          </li>
        </ul>
      </div>
      <div class="news_list_ziye" v-if="sixReservice">
        <ul>
          <li >
            <img :src="httpPath + sixReservice.imgUrl" alt="">
            <div class="text">
              <h2 :title="sixReservice.list[0].title">{{ sixReservice.list[0].title }}</h2>
              <p v-html="sixReservice.list[0].content"></p>
            </div>
          </li>
        </ul>
      </div>
      <div class="news_list_ziye fill-img" v-if="sevenReservice">
        <ul>
          <li >
            <h2 :title="sevenReservice.title" style="text-align: center;margin-bottom: 10px">{{ sevenReservice.title }}</h2>
            <img :src="httpPath + sevenReservice.imgUrl" alt="">
          </li>
        </ul>
      </div>
      <div class="sq_zhil px1200">
        <h2>我要加盟</h2>
        <p style="text-align: center">我们会在收到您的留言后24小时内对您进行回复</p>
        <div class="center-form">
          <el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-row>
              <el-col class="col-xs-12 col-md-6" >
                <el-form-item label="你的姓名" prop="name" style="width: 100%">
                  <input type="text" v-model="ruleForm.name">
                </el-form-item>
              </el-col>
              <el-col class="col-xs-12 col-md-6">
                <el-form-item label="联系方式" prop="mobile">
                  <input type="text" v-model="ruleForm.mobile">
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="col-xs-12 col-md-6" >
                <el-form-item label="公司名称" prop="companyName">
                  <input type="text" v-model="ruleForm.companyName">
                </el-form-item>
              </el-col>
              <el-col class="col-xs-12 col-md-6" >
                <el-form-item label="你的职位" prop="consultationType">
                  <el-select v-model="ruleForm.consultationType" placeholder="请选择" style="width: 100%">
                    <el-option
                      v-for="item in commonUtils.getDictPara('consult_type')"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="col-xs-12 col-md-12" >
                <el-form-item label="备注内容" prop="consultationContent">
                  <el-input :rows="6" type="textarea"
                            v-model="ruleForm.consultationContent"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="col-xs-12 col-md-12" >
                <div style="text-align: center">
                  <el-form-item>
                    <el-button type="warning" @click="submitForm('ruleForm')">加盟</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
        </el-form>
        </div>
      </div>
    </div>
  </div>

<!--  <div class="wrap-lg">-->
<!--    <div class="">-->
<!--      <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">招商加盟</span></div>-->
<!--    </div>-->
<!--    <div class="other-list-box" v-loading="loading">-->
<!--      <div class="other-box-content">-->
<!--        <div class="first-box-left">-->
<!--          <img :src="httpPath + firstReservice.imgUrl" alt=""/>-->
<!--        </div>-->
<!--        <div class="first-box-right">-->
<!--          <div style="padding: 20px;">-->
<!--            <div v-for="item in firstReservice.list" :key="item.id">-->
<!--              <div class="other-list-box-title beyond-hide">-->
<!--                <span :title="item.title">{{ item.title }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="center-image">-->
<!--      <img :src="httpPath + secondReservice.imgUrl">-->
<!--    </div>-->
<!--    <div class="center-image">-->
<!--      <img :src="httpPath + thirdReservice.imgUrl">-->
<!--    </div>-->
<!--    <div class="center-image">-->
<!--      <img :src="httpPath + fourthReservice.imgUrl">-->
<!--    </div>-->
<!--    &lt;!&ndash;         第二快内容&ndash;&gt;-->
<!--    <div class="other-list-box" v-loading="loading">-->
<!--      <div class="other-box-content">-->
<!--        <div class="second-box-left">-->
<!--          <img :src="httpPath + fiveReservice.imgUrl" alt=""/>-->
<!--        </div>-->
<!--        <div class="second-box-right">-->
<!--          <div class="other-list-box-title beyond-hide">-->
<!--            <span :title="fiveReservice.list[0].title">{{ fiveReservice.list[0].title }}</span>-->
<!--          </div>-->
<!--          <p class="other-list-box-content" v-html="fiveReservice.list[0].content"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;第三块内容&ndash;&gt;-->
<!--    <div class="other-list-box" v-loading="loading">-->
<!--      <div class="other-box-content">-->
<!--        <div class="second-box-left">-->
<!--          <img :src="httpPath + sixReservice.imgUrl" alt=""/>-->
<!--        </div>-->
<!--        <div class="second-box-right">-->
<!--          <div class="other-list-box-title">-->
<!--            <span :title="sixReservice.list[0].title">{{ sixReservice.list[0].title }}</span>-->
<!--          </div>-->
<!--          <p class="other-list-box-content" v-html="sixReservice.list[0].content"></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="other-list-box">-->
<!--      <div class="other-box-content">-->
<!--        <div style="margin: 0 auto; width: 1224px;">-->
<!--          <div style="text-align: center; margin: 30px;">-->
<!--            <div>{{sevenReservice.title}}</div>-->
<!--            <img :src="httpPath + sevenReservice.imgUrl">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;我要加盟&ndash;&gt;-->
<!--    <div class="wontjoin">-->
<!--      <div class="wontjoin-center">-->
<!--        <div class="center-title">-->
<!--          <div style="font-size: 16px; line-height: 30px; font-weight: bold; margin-top: 20px;">-->
<!--            我要加盟-->
<!--          </div>-->
<!--          <div style="font-size: 14px;">-->
<!--            我们会在收到您的留言后24小时内对您进行回复，感谢您的反馈！-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="center-form">-->
<!--          <el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"-->
<!--                   class="demo-ruleForm">-->
<!--            <el-form-item label="你的姓名" prop="name">-->
<!--              <el-input style="width: 260px;" v-model="ruleForm.name"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="联系方式" prop="mobile">-->
<!--              <el-input style="width: 260px;" v-model="ruleForm.mobile"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="公司名称" prop="companyName">-->
<!--              <el-input style="width: 260px;" v-model="ruleForm.companyName"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="你的职位" prop="consultationType">-->
<!--              <el-select v-model="ruleForm.consultationType" placeholder="请选择" style="width: 260px;">-->
<!--                <el-option-->
<!--                  v-for="item in commonUtils.getDictPara('consult_type')"-->
<!--                  :key="item.value"-->
<!--                  :label="item.name"-->
<!--                  :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="备注内容" prop="consultationContent">-->
<!--              <el-input style="width: 620px;" :rows="6" type="textarea"-->
<!--                        v-model="ruleForm.consultationContent"></el-input>-->
<!--            </el-form-item>-->
<!--            <div style="text-align: center">-->
<!--              <el-form-item>-->
<!--                <el-button type="warning" @click="submitForm('ruleForm')">加盟</el-button>-->
<!--                <el-button @click="resetForm('ruleForm')">重置</el-button>-->
<!--              </el-form-item>-->
<!--            </div>-->
<!--          </el-form>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import { getJoinLists } from '@/api/recommend/testing'
import { add, getInfo } from '@/api/platform/consulting'

export default {
  name: 'index',
  data () {
    // 校验电话号码是否已存在
    var validateMobile = (ryle, value, callback) => {
      var formdata = {
        mobile: value
      }
      getInfo(formdata)
        .then(res => {
          if (res.data.rtnCode !== '0') {
            callback(new Error('该电话号码已加盟！'))
          } else {
            callback()
          }
        })
        .catch(ex => {
        })
    }
    return {
      httpPath: '',
      ruleForm: {
        name: '',
        mobile: '',
        companyName: '',
        consultationType: '',
        consultationContent: ''
      },
      firstReservice: {},
      secondReservice: {},
      thirdReservice: {},
      fourthReservice: {},
      fiveReservice: {},
      sixReservice: {},
      sevenReservice: {},
      loading: false,
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请填写联系方式', trigger: 'blur' },
          { validator: validateMobile, trigger: 'change' }
        ]
      }

    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.getInfo()
  },
  methods: {
    getInfo () {
      let param = {
        categoryCode: '2'
      }
      this.loading = true
      getJoinLists(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          if (result.bizData && result.bizData.length > 0) {
            if (result.bizData.length > 0) {
              for (var i = 0; i < result.bizData.length; i++) {
                if (result.bizData[i].imgUrl !== '') {
                  var arr = result.bizData[i].imgUrl.split(',')
                  result.bizData[i].imgUrl = arr
                }
              }
            }
            this.firstReservice = result.bizData[0]
            this.secondReservice = result.bizData[1]
            this.thirdReservice = result.bizData[2]
            this.fourthReservice = result.bizData[3]
            this.fiveReservice = result.bizData[4]
            this.sixReservice = result.bizData[5]
            this.sevenReservice = result.bizData[6]
          }
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('加盟之后将不可修改资料, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            var formBody = this.ruleForm
            add(formBody).then(res => {
              var result = res.data
              if (res.data.rtnCode !== '0') {
                this.$message({
                  type: 'info',
                  message: result.msg
                })
              } else {
                this.$message({
                  type: 'success',
                  message: '加盟成功!'
                })
                this.$refs[formName].resetFields()
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消加盟'
            })
          })
        } else {
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.other-list-box {
  margin-top: 40px;
  width: 100%;
  color: #F6B95E;

  .other-box-content {
    display: inline-flex;
    border: 1px solid #f6b95e;
    background-color: #4f080e;
  }

  .other-list-box-title {
    font-weight: bold;
    font-size: 20px;
  }

  .other-list-box-content {
    font-size: 14px;
  }

  .first-box-left {
    width: 571px;
    height: 420px;
    margin: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .first-box-right {
    width: 613px;
    height: 420px;
  }

  .second-box-left {
    width: 571px;
    height: 420px;
    margin: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .second-box-right {
    width: 573px;
    height: 420px;
    margin: 20px;
  }
}

//中间图片部分
.center-image {
  width: 100%;
  height: 200px;
  margin-top: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}

/*我要加盟*/
.wontjoin {
  background: #4f080e;
  border: 1px solid #f6b95e;
  margin-top: 40px;
}

.wontjoin-center {
  width: 798px;
  margin: 0 auto;
}

.center-title {
  color: #f6b95e;
  text-align: center;
}

.center-form {
  margin-top: 30px;
}

/deep/ .el-form-item__label {
  //color: #f6b95e;
}
.news_list_ziye.fill-img li {
  text-align: center;
  float: inherit;
  img{
    width: auto;
    height: 100%;
    float: inherit;
    max-width: 100%;
  }

}
/deep/ .sq_zhil .el-form--inline .el-form-item {
  width: 100%;
  .el-form-item__content {
    width: calc(100% - 100px);
    input {
      width: 100%;
    }
  }
}
</style>
